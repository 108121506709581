import './App.css';
import NavbarComp from './components/NavbarComp'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import Contacto from './components/Contact';
import Index from './components/Index';
import Review from './components/Review';
import Step from './components/Step';
import Goal from './components/Goal';
import Infort from './components/Infort';
import Ways from './components/Ways';
import Blocked from './components/Blocked';
import Servicios from './components/Servicios';
import AccordionComponent from './components/FAQ'
import Footer from './components/Footer';
import FooterMobile from './components/FooterMobile'
import CotizacionMobile from './components/CotizarMobile';
import Gracias from './components/Gracias';
import MobileForm from './components/Form/MobileForm'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


function App() {
  return (
    <Router>
      <div className="App bg-navbar">
        <NavbarComp/>
        <Routes>
          <Route path="/" element={<>
            <Index/>
            <MobileForm />
            <Contacto/>
            <Review/>
            <Step/>
            <Goal/>
            <Infort/>
            <Blocked/>
            <Ways/>
            <Servicios/>
            <AccordionComponent/>
          </>} />
          <Route path="/cotizacion" element={<CotizacionMobile />} />
          <Route path="/contacto" element={<Contacto />} />
          <Route path='/gracias' element={<Gracias/>}/>
      </Routes>
      <Footer/>
      <FooterMobile/>
    </div>
    </Router>
  );
}

export default App;
