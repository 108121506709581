import React from 'react';
import { Container, Navbar, Nav, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useCustomNavigate } from '../utils/customNavigation';

function NavbarComp() {
  const customNavigate = useCustomNavigate();

  return (
    <>
      <div className='bg-700'>
        <p className='text-center text-white py-2 d-none d-md-block'>¿Tienes preguntas? Llámanos (1) 870-455-6742</p>
      </div>
      <Navbar className="bg-navbar2" variant="dark" expand="md" sticky="top">
        <Container>
          <Navbar.Brand >
            <img
              src="../../images/logotcp.png" // Asegúrate de que la ruta sea correcta.
              width="147"
              height="32"
              className="d-inline-block align-top"
              alt="Logo"
              onClick={() => customNavigate('#form')}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto "> {/* Alinea los elementos del Nav a la derecha */}
              <Nav.Link  onClick={() => customNavigate('#us')} className='text-font me-5'>Servicios</Nav.Link>
              <Nav.Link onClick={() => customNavigate('#faq')} className='text-font me-5'>FAQ</Nav.Link>
              <Nav.Link onClick={() => customNavigate('#contact')} className='text-font me-5'>Contacto</Nav.Link>

            <Button onClick={() => customNavigate('#form')} variant="outline-light" style={{backgroundColor: '#E21454', borderColor: '#E21454' }}>
              Cotizar ahora
            </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarComp;