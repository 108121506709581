import React from 'react';


const BlockedMobile = () => {
  return (
    <container className="d-block d-md-none text-center bg-faq mt-4 py-3">
        <div className='g-0'>
        <p className='bg-faq'>©2024 GARLAS LLC</p>
        </div>
    </container>
  );
};

export default BlockedMobile;