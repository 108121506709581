import React from 'react';
import '../Footer.css';
import { Row, Col } from 'react-bootstrap';
import { useCustomNavigate } from '../utils/customNavigation';

const Footer = () => {
  const customNavigate = useCustomNavigate();

  return (
    <footer className='p-5 d-none d-md-block'>
        <div className='d-flex justify-content-center align-items-center'>
            <div className='flex-column me-5'>
                <ul className='text-start list-unstyled me-5'>
                <li>
                    <h3><button  onClick={() => customNavigate('#form')}  className='btn f-home'>Home</button></h3>  
                </li>
                <li><button onClick={() => customNavigate('#form')} className='btn  text-no-dec text-white'>Cotiza</button></li>
                <li><button  onClick={() => customNavigate('#testimonios')}  className='btn  text-no-dec text-white'>Testimonios</button></li>
                <li><button  onClick={() => customNavigate('#us')}  className='btn  text-no-dec text-white'>Encuentra el transportista ideal</button></li>
                <li><button  onClick={() => customNavigate('#faq')}  className='btn  text-no-dec text-white'>FAQ</button></li>
            </ul>
            </div>
            <img src="../../images/logotcp.png" alt="Logo" className="footer-logo ms-5"  />
        </div>

      <Row>
        <Col md={12} className="footer-bottom f-text2">
        <div className='d-inline b-top'>
          <p>¿Preguntas? Llámanos al (1) 870-455-6742</p>
          </div>
          <div className='d-inline b-top '>
          <p>tucarroporamerica@garlas.com</p>
          </div>
          <div className='d-inline b-top'>
            <p>©2024 GARLAS LLC</p>
            </div>
        </Col>  
      </Row>
    </footer>   
  );
};

export default Footer;